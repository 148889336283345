const setActiveTab = (tab) => {
  return {
    type: 'SET_ACTIVE_TAB',
    activeTab: tab,
  };
};

export default {
  setActiveTab
};
