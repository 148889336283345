import { useContext, useEffect, useRef } from 'react';

import Analytics from '@aws-amplify/analytics';

import { AuthContext } from '../context/AuthContext';

const useAnalytics = (page) => {
  const { user } = useContext(AuthContext);
  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    if (user) {
      Analytics.record({
        name: `${page} Visit`,
        attributes: {
          username: user.attributes.email,
          org_id: user.attributes['custom:org_id'],
          org_name: user.attributes['custom:org_name'],
        },
      });
    }
    return () => (isMountedRef.current = false);
  }, [user, page]);
};

export default useAnalytics;
