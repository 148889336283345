const setPatientGivenName = (firstName) => {
    return {
        type: 'SET_PATIENT_GIVEN_NAME',
        payload: firstName
    };
};

const setPatientSurname = (lastName) => {
    return {
        type: 'SET_PATIENT_SURNAME',
        payload: lastName
    };
};

const setPatientDateOfBirth = (dob) => {
    return {
        type: 'SET_PATIENT_DATE_OF_BIRTH',
        payload: dob
    };
};

const setPatientGender = (gender) => {
    return {
        type: 'SET_PATIENT_GENDER',
        payload: gender
    };
};

const setPatientStreet = (street) => {
    return {
        type: 'SET_PATIENT_STREET',
        payload: street
    };
};

const setPatientCity = (city) => {
    return {
        type: 'SET_PATIENT_CITY',
        payload: city
    };
};

const setPatientState = (state) => {
    return {
        type: 'SET_PATIENT_STATE',
        payload: state
    };
};

const setPatientZip = (zip) => {
    return {
        type: 'SET_PATIENT_ZIP',
        payload: zip
    };
};

const setPatientDataSource = (dataSource) => {
    return {
        type: 'SET_PATIENT_DATA_SOURCE',
        payload: dataSource
    };
};

const setPatientIdentifier = (identifier) => {
    return {
        type: 'SET_PATIENT_IDENTIFIER',
        payload: identifier
    };
};

const setRecordId = (identifier) => {
    return {
        type: 'SET_RECORD_ID',
        payload: identifier
    };
};

export default {
    setPatientGivenName,
    setPatientSurname,
    setPatientDateOfBirth,
    setPatientGender,
    setPatientStreet,
    setPatientCity,
    setPatientState,
    setPatientZip,
    setPatientDataSource,
    setPatientIdentifier,
    setRecordId
};