const patientContext = (
    state = {
        PatientGivenName: "",
        PatientSurname: "",
        PatientDateOfBirth: "",
        PatientGender: "",
        PatientStreet: "",
        PatientCity: "",
        PatientState: "",
        PatientZip: "",
        PatientDataSource: "",
        PatientIdentifier: "",
        RecordId: ""
    },
    action
) => {
    switch (action.type) {
        case 'SET_PATIENT_GIVEN_NAME':
            return {
                ...state,
                PatientGivenName: action.payload,
            };
        case 'SET_PATIENT_SURNAME':
            return {
                ...state,
                PatientSurname: action.payload,
            };
        case 'SET_PATIENT_DATE_OF_BIRTH':
            return {
                ...state,
                PatientDateOfBirth: action.payload,
            };
        case 'SET_PATIENT_GENDER':
            return {
                ...state,
                PatientGender: action.payload,
            };
        case 'SET_PATIENT_STREET':
            return {
                ...state,
                PatientStreet: action.payload,
            };
        case 'SET_PATIENT_CITY':
            return {
                ...state,
                PatientCity: action.payload,
            };
        case 'SET_PATIENT_STATE':
            return {
                ...state,
                PatientState: action.payload,
            };
        case 'SET_PATIENT_ZIP':
            return {
                ...state,
                PatientZip: action.payload,
            };
        case 'SET_PATIENT_DATA_SOURCE':
            return {
                ...state,
                PatientDataSource: action.payload,
            };
        case 'SET_PATIENT_IDENTIFIER':
            return {
                ...state,
                PatientIdentifier: action.payload,
            };
        case 'SET_RECORD_ID':
            return {
                ...state,
                RecordId: action.payload,
            };
        default:
            return state;
    }
};

export default patientContext;
