import React, { useEffect, useRef, useState } from "react";

import useProgress from "use-progress";

import { Analytics, Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";

import Routes from "./routing/Routes";

import { parse } from "query-string";
import useAmplifyAuth from "./custom-hooks/useAmplifyAuth";

import { AuthProvider } from "./context/AuthContext";

import allActions from "./state/actions";

import IdleTimeoutModal from "./modals/IdleTimeoutModal/IdleTimeoutModal";
import InitialLoginModal from "./modals/InitialLoginModal/InitialLoginModal";
import TopNav from "./components/TopNav/TopNav";
import MessageBanner from "./components/MessageBanner/MessageBanner";
import SSOLogin from "./components/SSOLogin/SSOLogin";

import axios from 'axios';
import qs from 'qs';

import "./css/use-progress.css";

const App = () => {
  const template = process.env.REACT_APP_TEMPLATE;
  const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN;
  const redirectUrl = process.env.REACT_APP_REDIRECT_URL;
  const globalDispatch = useDispatch();

  const isMountedRef = useRef(null);

  const handleViprPatient = (payload) => {
    globalDispatch(allActions.viprActions.setPatient(payload));
  };

  const {
    state: { user, isLoggedIn },
    handleSignout,
    handleSSOSignout,
    dispatch,
  } = useAmplifyAuth();

  const [showSSOLogin, setShowSSOLogin] = useState(false);

  useProgress();

  useEffect(() => {
    isMountedRef.current = true;
    if (user) {
      Analytics.updateEndpoint({
        address: user.attributes.email,
        channelType: "EMAIL",
        optOut: "NONE",
        userId: user.attributes.sub,
      });
    }
    return () => (isMountedRef.current = false);
  });

  useEffect(() => {
    isMountedRef.current = true;
    globalDispatch(allActions.loginActions.setSourceOrganization(template));

    const favicon = document.getElementById("favicon");
    let publicUrl;
    if (url.includes("localhost")) {
      publicUrl = `http://${window.location.hostname}:3142`;
    } else {
      publicUrl = `https://${window.location.hostname}`;
    }
    const stylesheet = document.getElementById("template");
    const stylesheetUrl = process.env.REACT_APP_ASSETS_BUCKET;
    stylesheet.href = `${stylesheetUrl}/${template}/css/theme.css`;
    favicon.href = `${stylesheetUrl}/${template}/images/favicon.ico`;
    if (url.includes(process.env.REACT_APP_UPHIE_URL)) {
      globalDispatch(allActions.loginActions.setSourceOrganization("uphie"));
      favicon.href = `${publicUrl}/uphie_favicon.ico`;
      document.querySelector("title").textContent = "UPHIE";
    }

    return () => (isMountedRef.current = false);
  }, [globalDispatch, template]);

  function parseJwt(token) {
    if (!token) {
      return;
    }
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  }

  useEffect(() => {
    isMountedRef.current = true;
    if (user) {
      const setTokens = async () => {
        await Auth.currentSession().then((res) => {
          let accessToken = res.getAccessToken();
          let idToken = res.getIdToken();
          const refreshToken = res.getRefreshToken();
          let accessJwt = accessToken.getJwtToken();
          let idJwt = idToken.getJwtToken();
          const refreshJwt = refreshToken.getToken();
          globalDispatch(allActions.tokenActions.setAccessToken(accessJwt));
          globalDispatch(allActions.tokenActions.setIdToken(idJwt));
          globalDispatch(allActions.tokenActions.setRefreshToken(refreshJwt));
          let tokenData = parseJwt(idJwt);
          if (tokenData["custom:role"] === "admin") {
            globalDispatch(allActions.permissionsActions.setIsAdmin(true));
          }
          if (tokenData["custom:role"] === "super admin") {
            globalDispatch(allActions.permissionsActions.setIsSuperAdmin(true));
          }
          if (tokenData["custom:role"] === "org admin") {
            globalDispatch(allActions.permissionsActions.setIsOrgAdmin(true));
          }
          if (tokenData["custom:role"] === "data steward") {
            globalDispatch(
              allActions.permissionsActions.setIsDataSteward(true)
            );
          }
          if (tokenData["custom:role"] === "org admin") {
            globalDispatch(allActions.permissionsActions.setIsOrgAdmin(true));
          }
          globalDispatch(
            allActions.permissionsActions.setPermissions({
              accessTocViewer: JSON.parse(
                tokenData["custom:access_toc_viewer"]
                  ? tokenData["custom:access_toc_viewer"]
                  : "false"
              ),
              accessLegacyTocViewer: JSON.parse(
                tokenData["custom:access_legacy_toc_viewer"]
                  ? tokenData["custom:access_legacy_toc_viewer"]
                  : "false"
              ),
              accessRmViewer: JSON.parse(
                tokenData["custom:access_rm_viewer"]
                  ? tokenData["custom:access_rm_viewer"]
                  : "false"
              ),
              accessManageAcrs: JSON.parse(
                tokenData["custom:access_manage_acrs"]
                  ? tokenData["custom:access_manage_acrs"]
                  : "false"
              ),
              accessLegacyManageAcrs: JSON.parse(
                tokenData["custom:access_legacy_manage_acrs"]
                  ? tokenData["custom:access_legacy_manage_acrs"]
                  : "false"
              ),
              accessFileSub: JSON.parse(
                tokenData["custom:access_file_sub"]
                  ? tokenData["custom:access_file_sub"]
                  : "false"
              ),
              accessQmDash: JSON.parse(
                tokenData["custom:access_qm_dash"]
                  ? tokenData["custom:access_qm_dash"]
                  : "false"
              ),
              accessEcms: JSON.parse(
                tokenData["custom:access_ecms"]
                  ? tokenData["custom:access_ecms"]
                  : "false"
              ),
              accessRmDash: JSON.parse(
                tokenData["custom:access_rm_dash"]
                  ? tokenData["custom:access_rm_dash"]
                  : "false"
              ),
              accessVipr: JSON.parse(
                tokenData["custom:access_vipr"]
                  ? tokenData["custom:access_vipr"]
                  : "false"
              ),
              accessSdoh: JSON.parse(
                tokenData["custom:access_sdoh"]
                  ? tokenData["custom:access_sdoh"]
                  : "false"
              ),
              accessSolutionsCenter: JSON.parse(
                tokenData["custom:access_solutions_c"]
                  ? tokenData["custom:access_solutions_c"]
                  : "false"
              ),
              accessHd: JSON.parse(
                tokenData["custom:access_hd"]
                  ? tokenData["custom:access_hd"]
                  : "false"
              ),
              accessIqb: JSON.parse(
                tokenData["custom:access_iqb"]
                  ? tokenData["custom:access_iqb"]
                  : "false"
              ),
              accessOrus: JSON.parse(
                tokenData["custom:access_orus"]
                  ? tokenData["custom:access_orus"]
                  : "false"
              ),
              accessConform: JSON.parse(
                tokenData["custom:access_adt_conform"]
                  ? tokenData["custom:access_adt_conform"]
                  : "false"
              ),
              accessFallout: JSON.parse(
                tokenData["custom:access_adt_fallout"]
                  ? tokenData["custom:access_adt_fallout"]
                  : "false"
              ),
              accessDiretto: JSON.parse(
                tokenData["custom:access_diretto"]
                  ? tokenData["custom:access_diretto"]
                  : "false"
              ),
              accessHomePs: JSON.parse(
                tokenData["custom:access_home_ps"]
                  ? tokenData["custom:access_home_ps"]
                  : "false"
              ),
              accessOperations: JSON.parse(
                tokenData["custom:access_operations"]
                  ? tokenData["custom:access_operations"]
                  : "false"
              ),
              accessSdohReporting: JSON.parse(
                tokenData["custom:access_sdoh_reporting"]
                  ? tokenData["custom:access_sdoh_reporting"]
                  : "true"
              ),
              accessOutcomesViewer: JSON.parse(
                tokenData["custom:access_outcomes_viewer"]
                  ? tokenData["custom:access_outcomes_viewer"]
                  : "true"
              ),
            })
          );
          globalDispatch(
            allActions.permissionsActions.setOrgName(
              tokenData["custom:org_name"] ? tokenData["custom:org_name"] : ""
            )
          );
        })
          .catch((err) => {
            console.log("error setting tokens " + err)
          });
      };
      setTokens();
    }

    return () => (isMountedRef.current = false);
  }, [user, globalDispatch]);

  const url = window.location.href;
  const [extAccessToken, setExtAccessToken] = useState(null);
  const [extIdToken, setExtIdToken] = useState(null);
  const [extRefreshToken, setExtRefreshToken] = useState(null);
  const [extUser, setExtUser] = useState(false);
  const location = useLocation();

  const fetchSSOTokens = async (code, client) => {
    setShowSSOLogin(true);
    let data = qs.stringify({
      'grant_type': 'authorization_code',
      'client_id': client,
      'code': code,
      'redirect_uri': redirectUrl
    });

    const requestOptions = {
      url: cognitoDomain + '/oauth2/token',
      method: 'POST',
      redirect: 'follow',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      },
      data: data
    };

    await axios(requestOptions)
      .then(function (res) {
        setExtRefreshToken(res.data.refresh_token);
        setExtAccessToken(res.data.access_token);
        setExtIdToken(res.data.id_token);
        setExtUser(true);
        setShowSSOLogin(false);
      })
      .catch(function (error) {
        console.log("Failed to retrieve refresh token: " + error);
        setShowSSOLogin(false);
      });
  }

  useEffect(() => {
    isMountedRef.current = true;
    const search = parse(location.search)
    const { code, state } = search

    // check if recordId was sent in state (SSO use case)
    if (state && code && state != "") {
      // first half of state is patient context record ID, second half is client ID
      let splitState = state.split("*");
      let recordId = splitState[1];
      let client = splitState[2];

      globalDispatch(allActions.patientContextActions.setRecordId(recordId));
      fetchSSOTokens(code, client);
    }

    return () => (isMountedRef.current = false);
  }, []);

  useEffect(() => {
    isMountedRef.current = true;
    if (extUser) {
      console.log("external user found");

      //sets the SSO specific idle timeout 
      handleSSOSignout();
      dispatch({ type: "SET_IS_LOGGED_IN", isLoggedIn: true });
      globalDispatch(allActions.tokenActions.setAccessToken(extAccessToken));
      globalDispatch(allActions.tokenActions.setIdToken(extIdToken));
      globalDispatch(allActions.tokenActions.setRefreshToken(extRefreshToken));

      let tokenData = parseJwt(extIdToken);
      globalDispatch(
        allActions.permissionsActions.setPermissions({
          accessHomePs: JSON.parse(
            tokenData["custom:access_home_ps"]
              ? tokenData["custom:access_home_ps"]
              : "false"
          ),
          accessTocViewer: JSON.parse(
            tokenData["custom:access_toc_viewer"]
              ? tokenData["custom:access_toc_viewer"]
              : "false"
          ),
        })
      );
    }
    return () => (isMountedRef.current = false);
  }, [globalDispatch, extUser]);

  return (
    <>
      {showSSOLogin &&
        <AuthProvider
          value={{
            user,
            handleSignout,
            handleSSOSignout,
            isLoggedIn,
            handleViprPatient,
            extUser,
          }}
        >
          <>
            <div
              style={{ height: "100%" }}
              className={
                url.includes(process.env.REACT_APP_UPHIE_URL)
                  ? "uphie-main-wrap"
                  : "main-wrap"
              }
            >
              <SSOLogin />
              <TopNav />
              <Routes />
              {user && <IdleTimeoutModal />}
              <InitialLoginModal />
            </div>
          </>
        </AuthProvider>
      }
      {!showSSOLogin &&
        <AuthProvider
          value={{
            user,
            handleSignout,
            handleSSOSignout,
            isLoggedIn,
            handleViprPatient,
            extUser,
          }}
        >
          <>
            <div
              style={{ height: "100%" }}
              className={
                url.includes(process.env.REACT_APP_UPHIE_URL)
                  ? "uphie-main-wrap"
                  : "main-wrap"
              }
            >
              {/*<MessageBanner />*/}
              <TopNav />
              <Routes />
              {user && <IdleTimeoutModal />}
              <InitialLoginModal />
            </div>
          </>
        </AuthProvider>
      }
    </>
  );
};

export default withRouter(App);
