import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import rootReducer from './state/reducers';
import 'bootstrap/dist/css/bootstrap.css';
import './css/index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth, API, Analytics } from 'aws-amplify';

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

Auth.configure({
  identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  region: process.env.REACT_APP_USER_POOL_REGION,
  identityPoolRegion: process.env.REACT_APP_USER_POOL_REGION,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  storage: sessionStorage,
});

API.configure({
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_USER_POOL_REGION,
  aws_appsync_authenticationType: process.env.REACT_APP_GRAPHQL_AUTH_TYPE,
  endpoints: [
    {
                name: "UtilityHubAPI",
                endpoint: process.env.REACT_APP_UTILITY_HUB_API_ENDPOINT
    }
  ]
});

Analytics.configure({
  AWSPinpoint: {
    appId: process.env.REACT_APP_PINPOINT_APP_ID,
    region: process.env.REACT_APP_USER_POOL_REGION,
    manadatorySignIn: false,
  },
});

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
