const setShowAddUserModal = (tf) => {
  return {
    type: 'SET_SHOW_ADD_USER_MODAL',
    payload: tf,
  };
};

const setShowEditUserModal = (tf) => {
  return {
    type: 'SET_SHOW_EDIT_USER_MODAL',
    payload: tf,
  };
};

const setShowExpiredPasswordModal = (tf) => {
  return {
    type: 'SET_SHOW_EXPIRED_PASSWORD_MODAL',
    payload: tf,
  };
};

const setShowExpiredPasswordConfirmationModal = (tf) => {
  return {
    type: 'SET_SHOW_EXPIRED_PASSWORD_CONFIRMATION_MODAL',
    payload: tf,
  };
};

const setIsLoadingModal = (tf) => {
  return {
    type: 'SET_IS_LOADING_MODAL',
    isLoadingModal: tf,
  };
};

const setShowErrorModal = (tf, errorType) => {
  return {
    type: 'SET_SHOW_ERROR_MODAL',
    showErrorModal: tf,
    errorType: errorType,
  };
};

const setShowSuccessModal = (tf) => {
  return {
    type: 'SET_SHOW_SUCCESS_MODAL',
    showSuccessModal: tf,
  };
};

const setShowBulkAddPracticesModal = (tf) => {
  return {
    type: 'SET_SHOW_BULK_ADD_PRACTICES_MODAL',
    showBulkAddPracticesModal: tf,
  };
};

const setShowEditOrgModal = (tf, org) => {
  return {
    type: 'SET_SHOW_EDIT_ORG_MODAL',
    org: org,
    showEditOrgModal: tf,
  };
};

const setShowConfirmationModal = (tf) => {
  return {
    type: 'SET_SHOW_CONFIRMATION_MODAL',
    showConfirmationModal: tf,
  };
};

export default {
  setShowAddUserModal,
  setShowBulkAddPracticesModal,
  setShowConfirmationModal,
  setShowEditOrgModal,
  setShowEditUserModal,
  setShowExpiredPasswordModal,
  setShowExpiredPasswordConfirmationModal,
  setShowErrorModal,
  setShowSuccessModal,
  setIsLoadingModal,
};
